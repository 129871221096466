export type Player = {
  _id: string;
  gold: number;
  xp: number;
  nextCastTime: Date;
  isFollowing: boolean;
  rod: number;
  lure: number;
  bait: number;
  line: number;
  purchasedItems: number[];
};

export type DockPlayer = {
  _id: string;
  xp: number;
  seat: number;
  lastActive: Date;
};

export type Dock = {
  _id: string;
  limit: number;
  channel: string;
  players: DockPlayer[];
  biome: DockBiome;
  fishLeft: number;
};

export type Fish = {
  _id: string;
  type: FishType;
  username: string;
  float: number;
  isShiny: boolean;
  weight: number;
  age: number;
  value: number;
  version: number;
  rarity: Rarity;
  gender: FishGender;
};

export enum DockBiome {
  River,
  Pier,
  Cave,
  Swamp,
  Reef,
  Abyss,
  // Ocean = 2
  // Reef = 20,
  // Island = 21,
  // Beach = 22,
  // Coast = 23,
  // Cave = 3,
  // Swamp = 4,
  // Desert = 5,
  // Jungle = 6,
  // Volcano = 7,
  // Space = 9,
  // Hell = 10,
  // Abyss = 11,
  // Void = 12,
  // Underworld = 13,
  // Dungeon = 14,
  // Mountain = 16,
  // Glacier = 17,
}

export enum Rarity {
  VeryCommon,
  Common,
  Uncommon,
  Scarce,
  Rare,
  Epic,
  Exotic,
  Extraordinary,
  Legendary,
  Mythical,
  Ethereal,
  Divine,
  Celestial,
  Godly,
  Demonic,
  Infernal,
}

export enum FishGender {
  Male,
  Female,
}

export enum ShopItemType {
  WoodRod,
  BambooRod,
  FiberglassRod,
  Spear,
  Net,
  Trap,
  Rusty,
  Steel,
  Double,
  Barbed,
  SnapSwivel,
  Unknown1,
  Unknown2,
  None,
  Sardines,
  Worm,
  Caterpillar,
  Crab,
  Shrimp,
  Ammonite,
  Braided,
  Coated,
  Line3,
  Line4,
  Line5,
  Line6,
  Line7,
  Line8,
  AluminumRod,
  CarbonFiberRod,
  TitaniumRod,
  CopperHook,
  GraphiteRod,
}

export enum ItemClass {
  Rod,
  Bait,
  Lure,
  Line,
}

export type ShopItem = {
  name: string;
  description: string[];
  path: string;
  cost: number;
  class: ItemClass;
  costPerCast?: boolean;
  // type: ShopItemType;
};

export const shopItemConfig: Record<ShopItemType, ShopItem> = {
  [ShopItemType.WoodRod]: {
    name: "Wood rod",
    description: ["-4s cast time.", "+15% super cast chance in the river."],
    path: "icon37",
    cost: 0,
    class: ItemClass.Rod,
  },
  [ShopItemType.BambooRod]: {
    name: "Bamboo rod",
    description: [
      "-6s cast time.",
      "fish size = 0.7x",
      "Good for catching smaller fish.",
    ],
    path: "icon38",
    cost: 500,
    class: ItemClass.Rod,
  },
  [ShopItemType.GraphiteRod]: {
    name: "Graphite rod",
    description: [
      "-15s cast time.",
      "+3% tangle chance.",
      "+20s random cast time.",
    ],
    path: "icon38",
    cost: 1500,
    class: ItemClass.Rod,
  },
  [ShopItemType.FiberglassRod]: {
    name: "Fiberglass rod",
    description: [
      "-8s cast time.",
      "+4% tangle chance.",
      "+5s random cast time.",
    ],
    path: "icon39",
    cost: 3500,
    class: ItemClass.Rod,
  },
  [ShopItemType.AluminumRod]: {
    name: "Aluminum rod",
    description: [
      "+8s base cast time.",
      "+6% tangle chance.",
      "fish size = 0.4x + 0.6",
      "Good for catching larger fish.",
      "+10% super cast chance in the Abyss.",
    ],
    path: "icon39",
    cost: 4000,
    class: ItemClass.Rod,
  },
  [ShopItemType.TitaniumRod]: {
    name: "Titanium rod",
    description: [
      "+34s base cast time.",
      "+10% tangle chance.",
      "+24% chance to super cast.",
      "-10% seaweed chance in the swamp.",
    ],
    path: "icon39",
    cost: 8000,
    class: ItemClass.Rod,
  },
  [ShopItemType.CarbonFiberRod]: {
    name: "Carbon fiber rod",
    description: [
      "-4s base cast time.",
      "+5% tangle chance.",
      "+8% chance to super cast.",
    ],
    path: "icon39",
    cost: 15000,
    class: ItemClass.Rod,
  },
  [ShopItemType.Spear]: {
    name: "Spear",
    description: ["75% chance to catch 2 fish.", "+15s base cast time."],
    path: "icon40",
    cost: 30000,
    class: ItemClass.Rod,
  },
  [ShopItemType.Net]: {
    name: "Net",
    description: [
      "+150s base cast time.",
      "catches 2-5 fish.",
      "+25% tangle chance.",
      "-8% chance to super cast.",
    ],
    path: "icon41",
    cost: 50000,
    class: ItemClass.Rod,
  },
  [ShopItemType.Trap]: {
    name: "Trap",
    description: [
      "+4m base cast time.",
      "+100% chance to super cast.",
      "+25% tangle chance.",
    ],
    path: "icon42",
    cost: 120000,
    class: ItemClass.Rod,
  },
  // Hooks
  [ShopItemType.Rusty]: {
    name: "Rusty hook",
    description: ["-18% seaweed catch rate."],
    path: "icon43",
    cost: 0,
    class: ItemClass.Lure,
  },
  [ShopItemType.CopperHook]: {
    name: "Copper hook",
    description: ["-10% seaweed catch rate.", "+4% chance to super cast."],
    path: "icon44",
    cost: 900,
    class: ItemClass.Lure,
  },
  [ShopItemType.Steel]: {
    name: "Steel hook",
    description: ["-2% seaweed catch rate.", "+8% chance to super cast."],
    path: "icon44",
    cost: 2400,
    class: ItemClass.Lure,
  },
  [ShopItemType.Double]: {
    name: "Double hook",
    description: ["+5% seaweed catch rate.", "Avoids common fish."],
    path: "icon45",
    cost: 8000,
    class: ItemClass.Lure,
  },
  [ShopItemType.Barbed]: {
    name: "Barbed hook",
    description: ["+10% seaweed catch rate.", "Avoids very common fish."],
    path: "icon46",
    cost: 20000,
    class: ItemClass.Lure,
  },
  [ShopItemType.SnapSwivel]: {
    name: "Snap swivel",
    description: ["-25% seaweed catch rate."],
    path: "icon34",
    cost: 35000,
    class: ItemClass.Lure,
  },
  [ShopItemType.Unknown1]: {
    name: "Unknown",
    description: [],
    path: "icon35",
    cost: 9999999,
    class: ItemClass.Lure,
  },
  [ShopItemType.Unknown2]: {
    name: "Unknown",
    description: [],
    path: "icon36",
    cost: 9999999,
    class: ItemClass.Lure,
  },
  [ShopItemType.None]: {
    name: "None",
    description: ["Can catch most kinds of fish."],
    path: "close",
    cost: 0,
    class: ItemClass.Lure,
  },
  [ShopItemType.Sardines]: {
    name: "Sardines",
    description: ["Can catch ethereal fish."],
    path: "icon33",
    cost: 25,
    costPerCast: true,
    class: ItemClass.Bait,
  },
  [ShopItemType.Worm]: {
    name: "Worm",
    description: ["Can catch divine fish."],
    path: "icon31",
    cost: 50,
    costPerCast: true,
    class: ItemClass.Bait,
  },
  [ShopItemType.Caterpillar]: {
    name: "Caterpillar",
    description: ["Can catch celestial fish."],
    path: "icon32",
    cost: 150,
    costPerCast: true,
    class: ItemClass.Bait,
  },
  [ShopItemType.Crab]: {
    name: "Crab",
    description: ["Can catch godly fish."],
    path: "icon28",
    cost: 250,
    costPerCast: true,
    class: ItemClass.Bait,
  },
  [ShopItemType.Shrimp]: {
    name: "Shrimp",
    description: ["Can catch demonic fish."],
    path: "icon29",
    cost: 500,
    costPerCast: true,
    class: ItemClass.Bait,
  },
  [ShopItemType.Ammonite]: {
    name: "Ammonite",
    description: ["Can catch infernal fish."],
    path: "icon30",
    cost: 1000,
    costPerCast: true,
    class: ItemClass.Bait,
  },
  // Lines
  [ShopItemType.Braided]: {
    name: "Braided line",
    description: ["+6s random cast time.", "-1% tangle chance."],
    path: "icon47",
    cost: 0,
    class: ItemClass.Line,
  },
  [ShopItemType.Coated]: {
    name: "Coated line",
    description: [
      "+10s random cast time.",
      "-2% tangle chance.",
      "+2% chance to super cast.",
    ],
    path: "icon48",
    cost: 5000,
    class: ItemClass.Line,
  },
  [ShopItemType.Line3]: {
    name: "Line 3",
    description: ["+18s random cast time.", "+7% chance to super cast."],
    path: "icon48",
    cost: 20000,
    class: ItemClass.Line,
  },
  [ShopItemType.Line4]: {
    name: "Line 4",
    description: ["-10s random cast time.", "-4% chance to super cast."],
    path: "icon48",
    cost: 30000,
    class: ItemClass.Line,
  },
  [ShopItemType.Line5]: {
    name: "Line 5",
    description: ["+10s cast time.", "Can catch celestial fish."],
    path: "icon48",
    cost: 80000,
    class: ItemClass.Line,
  },
  [ShopItemType.Line6]: {
    name: "Line 6",
    description: ["+10s cast time.", "Can catch godly fish."],
    path: "icon48",
    cost: 100000,
    class: ItemClass.Line,
  },
  [ShopItemType.Line7]: {
    name: "Line 7",
    description: ["+10s cast time.", "Can catch demonic fish."],
    path: "icon48",
    cost: 120000,
    class: ItemClass.Line,
  },
  [ShopItemType.Line8]: {
    name: "Line 8",
    description: ["+10s cast time.", "Can catch infernal fish."],
    path: "icon48",
    cost: 140000,
    class: ItemClass.Line,
  },
};

export enum FishType {
  BrownTrout,
  Perch,
  Carp,
  YellowPerch,
  Pike,
  LargeMouthBass,
  SmallMouthBass,
  Sunfish,
  Catfish,
  Crawfish,
  SoftShelledTurtle,
  Crab,
  Salmon,
  Shrimp,
  Eel,
  Starfish,
  Octopus,
  Squid,
  Jellyfish,
  RainbowTrout,
  Walleye,
  BoxTurtle,
  SnappingTurtle,
  SpottedTurtle,
  Lobster,
  LeatherbackTurtle,
  BrookTrout,
  Cod,
  Mackerel,
  Pickerel,
  RockBass,
  Tuna,
  Pufferfish,
  Sardine,
  SeaBass,
  Swordfish,
  Tilapia,
  Whitefish,
  Zander,
  Bluegill,
  Bullhead,
  Chub,
  Crappie,
  Flounder,
  Gar,
  Herring,
  Redfish,
  Snapper,
  Snook,
  Sturgeon,
  Trout,
  SeaTurtle,
  BlueCrab,
  KingCrab,
  LionsManeJellyfish,
  MantisShrimp,
  PistolShrimp,
  ElectricEel,
  MorayEel,
  BlueSeaStar,
  RoyalStarfish,
  ThornySeahorse,
  SpottedSeahorse,
  YellowSeahorse,
  Angler, //34
  LanternFish, //15
  GulperEel, //47
  Barreleye, // 20
  BlobFish, //23
  Cavefish, // 25

  Minnow, // Cave //02
  Goby, // Cave //58
  Coelacanth, // Cave //41
  Tetra, // Cave // 13
  Anglefish, // Cave // 35
  SliderTurtle, // Cave // 77

  // Squids,
  VampireSquid, // Abyss //95
  FireflySquid, // Abyss //96
  BottletailSquid, // Cave // 97
  BobtailSquid, // Abyss // 97
  BigfinSquid, // Abyss //96
  GlassSquid, // Abyss // 95

  // Jellyfish
  MoonJelly, // Abyss
  PhantomJelly, // Abyss
  CombJelly, // Abyss

  // ManOWar, // Pier
  // CrystalJelly, // Pier
  // FlowerHatJelly, // Pier
  // SeaNettle, // Pier
  // BoxJellyfish, // pier

  MolaMola, // Pier // 75
  MolaTeca, // Pier // 76

  // Misc
  // SeaAngel,
  // SeaBunny,
  // Isopod,
  // Clam,
  // Oyster,

  // Jungle fish
  // Pirana
  // Pirarucu
  // Aruanãfish
  // Tambaqui
  // Barracuda
}
enum LifespanClass {
  Short,
  Medium,
  Long,
  VeryLong,
}

type FishConfig = {
  weightClass: WeightClass;
  lifespanClass: LifespanClass;
  rarity: Rarity;
  biome?: DockBiome;
};

enum WeightClass {
  Tiny,
  Light,
  Medium,
  Heavy,
  VeryHeavy,
  Colossal,
}
export const fishTypeNameMap: Record<FishType, string> = {
  [FishType.BrownTrout]: "Brown trout",
  [FishType.Perch]: "Perch",
  [FishType.Carp]: "Carp",
  [FishType.YellowPerch]: "Yellow perch",
  [FishType.Pike]: "Pike",
  [FishType.LargeMouthBass]: "Large mouth bass",
  [FishType.SmallMouthBass]: "Small mouth bass",
  [FishType.Sunfish]: "Sunfish",
  [FishType.Catfish]: "Catfish",
  [FishType.Crawfish]: "Crawfish",
  [FishType.SoftShelledTurtle]: "Soft-shelled turtle",
  [FishType.Crab]: "Crab",
  [FishType.Salmon]: "Salmon",
  [FishType.Shrimp]: "Shrimp",
  [FishType.Eel]: "Eel",
  [FishType.Squid]: "Squid",
  [FishType.Octopus]: "Octopus",
  [FishType.Jellyfish]: "Jellyfish",
  [FishType.Starfish]: "Starfish",
  [FishType.RainbowTrout]: "Rainbow trout",
  [FishType.Lobster]: "Lobster",
  [FishType.Walleye]: "Walleye",
  [FishType.BoxTurtle]: "Box turtle",
  [FishType.SnappingTurtle]: "Snapping turtle",
  [FishType.SeaTurtle]: "Sea turtle",
  [FishType.SpottedTurtle]: "Spotted turtle",
  [FishType.LeatherbackTurtle]: "Leatherback turtle",
  [FishType.Pufferfish]: "Pufferfish",
  [FishType.Sardine]: "Sardine",
  [FishType.SeaBass]: "Sea bass",
  [FishType.Swordfish]: "Swordfish",
  [FishType.Tilapia]: "Tilapia",
  [FishType.Whitefish]: "Whitefish",
  [FishType.Zander]: "Zander",
  [FishType.Bluegill]: "Bluegill",
  [FishType.Bullhead]: "Bullhead",
  [FishType.Chub]: "Chub",
  [FishType.Crappie]: "Crappie",
  [FishType.Flounder]: "Flounder",
  [FishType.Gar]: "Gar",
  [FishType.Herring]: "Herring",
  [FishType.Redfish]: "Redfish",
  [FishType.Snapper]: "Snapper",
  [FishType.Snook]: "Snook",
  [FishType.Sturgeon]: "Sturgeon",
  [FishType.Trout]: "Trout",
  [FishType.Tuna]: "Tuna",
  [FishType.BrookTrout]: "Brook trout",
  [FishType.Cod]: "Cod",
  [FishType.Mackerel]: "Mackerel",
  [FishType.Pickerel]: "Pickerel",
  [FishType.RockBass]: "Rock bass",
  [FishType.BlueCrab]: "Blue crab",
  [FishType.KingCrab]: "King crab",
  [FishType.LionsManeJellyfish]: "Lion's mane jellyfish",
  [FishType.MantisShrimp]: "Mantis shrimp",
  [FishType.PistolShrimp]: "Pistol shrimp",
  [FishType.ElectricEel]: "Electric eel",
  [FishType.MorayEel]: "Moray eel",
  [FishType.BlueSeaStar]: "Blue sea star",
  [FishType.RoyalStarfish]: "Royal starfish",
  [FishType.ThornySeahorse]: "Thorny seahorse",
  [FishType.SpottedSeahorse]: "Spotted seahorse",
  [FishType.YellowSeahorse]: "Yellow seahorse",
  [FishType.Angler]: "Angler",
  [FishType.LanternFish]: "Lantern fish",
  [FishType.GulperEel]: "Gulper eel",
  [FishType.Barreleye]: "Barreleye",
  [FishType.BlobFish]: "Blob fish",
  [FishType.Cavefish]: "Cavefish",
  [FishType.Minnow]: "Minnow",
  [FishType.Goby]: "Goby",
  [FishType.Coelacanth]: "Coelacanth",
  [FishType.Tetra]: "Tetra",
  [FishType.Anglefish]: "Anglefish",
  [FishType.SliderTurtle]: "Slider turtle",
  [FishType.VampireSquid]: "Vampire squid",
  [FishType.FireflySquid]: "Firefly squid",
  [FishType.BottletailSquid]: "Bottletail squid",
  [FishType.BobtailSquid]: "Bobtail squid",
  [FishType.BigfinSquid]: "Bigfin squid",
  [FishType.GlassSquid]: "Glass squid",
  [FishType.MoonJelly]: "Moon jelly",
  [FishType.PhantomJelly]: "Phantom jelly",
  [FishType.CombJelly]: "Comb jelly",
  [FishType.MolaMola]: "Mola mola",
  [FishType.MolaTeca]: "Mola teca",
};

export const rarityNameMap: Record<Rarity, string> = {
  [Rarity.VeryCommon]: "Very common",
  [Rarity.Common]: "Common",
  [Rarity.Uncommon]: "Uncommon",
  [Rarity.Scarce]: "Scarce",
  [Rarity.Rare]: "Rare",
  [Rarity.Epic]: "Epic",
  [Rarity.Exotic]: "Exotic",
  [Rarity.Extraordinary]: "Extraordinary",
  [Rarity.Legendary]: "Legendary",
  [Rarity.Mythical]: "Mythical",
  // [Rarity.Ancient]: "Ancient",
  //
  [Rarity.Ethereal]: "Ethereal",
  [Rarity.Divine]: "Divine",
  [Rarity.Celestial]: "Celestial",
  [Rarity.Godly]: "Godly",
  [Rarity.Demonic]: "Demonic",
  [Rarity.Infernal]: "Infernal",
};

export const LifespanMap: Record<LifespanClass, number> = {
  [LifespanClass.Short]: 1,
  [LifespanClass.Medium]: 2,
  [LifespanClass.Long]: 3,
  [LifespanClass.VeryLong]: 4,
};

export const WeightMap: Record<WeightClass, number> = {
  [WeightClass.Tiny]: 0.5,
  [WeightClass.Light]: 2,
  [WeightClass.Medium]: 5,
  [WeightClass.Heavy]: 40,
  [WeightClass.VeryHeavy]: 100,
  [WeightClass.Colossal]: 500,
};

export const fishConfigMap: Record<FishType, FishConfig> = {
  // River ********************** 14
  [FishType.Mackerel]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.VeryCommon,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.River,
  },
  [FishType.BrownTrout]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.VeryCommon,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.River,
  },
  [FishType.Perch]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.VeryCommon,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.River,
  },
  [FishType.BrookTrout]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Common,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.River,
  },
  [FishType.YellowPerch]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Common,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.River,
  },
  [FishType.Snook]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Common,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.River,
  },
  [FishType.Crappie]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Common,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.River,
  },
  [FishType.Chub]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Uncommon,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.River,
  },
  [FishType.RockBass]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Uncommon,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.River,
  },
  [FishType.SmallMouthBass]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Uncommon,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.River,
  },
  [FishType.Pickerel]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Scarce,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.River,
  },
  [FishType.Gar]: {
    weightClass: WeightClass.Heavy,
    rarity: Rarity.Rare,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.River,
  },
  [FishType.Whitefish]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Rare,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.River,
  },
  [FishType.Bluegill]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Epic,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.River,
  },
  [FishType.SpottedTurtle]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Epic,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.River,
  },
  [FishType.Zander]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Exotic,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.River,
  },
  [FishType.Salmon]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Extraordinary,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.River,
  },
  [FishType.SnappingTurtle]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Legendary,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.River,
  },
  [FishType.RainbowTrout]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Mythical,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.River,
  },

  // Swamp ********************** 11
  [FishType.Trout]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.VeryCommon,
    lifespanClass: LifespanClass.Medium,
  },
  [FishType.LargeMouthBass]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Common,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Swamp,
  },
  [FishType.Walleye]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Uncommon,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Swamp,
  },
  [FishType.Bullhead]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Scarce,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Swamp,
  },
  [FishType.BoxTurtle]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Rare,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.River,
  },
  [FishType.Pike]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Epic,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Swamp,
  },
  [FishType.Crawfish]: {
    weightClass: WeightClass.Tiny,
    rarity: Rarity.Epic,
    lifespanClass: LifespanClass.Short,
    biome: DockBiome.Swamp,
  },
  [FishType.SoftShelledTurtle]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Exotic,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Swamp,
  },
  [FishType.Eel]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Extraordinary,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Swamp,
  },
  [FishType.MorayEel]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Legendary,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Swamp,
  },
  [FishType.ElectricEel]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Mythical,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Swamp,
  },

  // Pier ********************** 17
  [FishType.Sardine]: {
    weightClass: WeightClass.Tiny,
    rarity: Rarity.VeryCommon,
    lifespanClass: LifespanClass.Short,
    biome: DockBiome.Pier,
  },
  [FishType.Tilapia]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.VeryCommon,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Pier,
  },
  [FishType.SeaBass]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Common,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Pier,
  },
  [FishType.Snapper]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Uncommon,
    lifespanClass: LifespanClass.Medium,
  },
  [FishType.Redfish]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Scarce,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Pier,
  },
  [FishType.Cod]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Scarce,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Pier,
  },
  [FishType.Tuna]: {
    weightClass: WeightClass.Colossal,
    rarity: Rarity.Rare,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Pier,
  },
  [FishType.YellowSeahorse]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Epic,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Pier,
  },
  [FishType.SpottedSeahorse]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Exotic,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Pier,
  },
  [FishType.Jellyfish]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Exotic,
    lifespanClass: LifespanClass.Short,
    biome: DockBiome.Pier,
  },
  [FishType.Swordfish]: {
    weightClass: WeightClass.VeryHeavy,
    rarity: Rarity.Exotic,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Pier,
  },
  [FishType.LeatherbackTurtle]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Extraordinary,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Pier,
  },
  [FishType.ThornySeahorse]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Extraordinary,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Pier,
  },
  [FishType.Sunfish]: {
    weightClass: WeightClass.Heavy,
    rarity: Rarity.Extraordinary,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Pier,
  },
  [FishType.LionsManeJellyfish]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Legendary,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Pier,
  },
  [FishType.MolaMola]: {
    weightClass: WeightClass.Heavy,
    rarity: Rarity.Legendary,
    lifespanClass: LifespanClass.VeryLong,
    biome: DockBiome.Pier,
  },
  [FishType.MantisShrimp]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Mythical,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Pier,
  },
  [FishType.Sturgeon]: {
    weightClass: WeightClass.Heavy,
    rarity: Rarity.Mythical,
    lifespanClass: LifespanClass.VeryLong,
    biome: DockBiome.Pier,
  },
  [FishType.MolaTeca]: {
    weightClass: WeightClass.Heavy,
    rarity: Rarity.Mythical,
    lifespanClass: LifespanClass.VeryLong,
    biome: DockBiome.Pier,
  },

  // Abyss ********************** 7
  [FishType.LanternFish]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.VeryCommon,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Abyss,
  },
  [FishType.FireflySquid]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Common,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Abyss,
  },
  [FishType.BlobFish]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Uncommon,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Abyss,
  },
  [FishType.GlassSquid]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Scarce,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Abyss,
  },
  [FishType.GulperEel]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Rare,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Abyss,
  },
  [FishType.MoonJelly]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Rare,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Abyss,
  },
  [FishType.BobtailSquid]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Epic,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Abyss,
  },
  [FishType.Angler]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Exotic,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Abyss,
  },
  [FishType.CombJelly]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Exotic,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Abyss,
  },
  [FishType.VampireSquid]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Exotic,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Abyss,
  },
  [FishType.Squid]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Extraordinary,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Abyss,
  },
  [FishType.Barreleye]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Extraordinary,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Abyss,
  },
  [FishType.PhantomJelly]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Legendary,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Abyss,
  },
  [FishType.BigfinSquid]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Mythical,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Abyss,
  },

  // Reef ********************** 12
  [FishType.Herring]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.VeryCommon,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Reef,
  },
  [FishType.Shrimp]: {
    weightClass: WeightClass.Tiny,
    rarity: Rarity.Common,
    lifespanClass: LifespanClass.Short,
    biome: DockBiome.Reef,
  },
  [FishType.Crab]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Uncommon,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Reef,
  },
  [FishType.Flounder]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Scarce,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Reef,
  },
  [FishType.BlueSeaStar]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Rare,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Reef,
  },
  [FishType.Lobster]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Rare,
    lifespanClass: LifespanClass.VeryLong,
    biome: DockBiome.Reef,
  },
  [FishType.Starfish]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Epic,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Reef,
  },
  [FishType.Pufferfish]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Exotic,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Reef,
  },
  [FishType.PistolShrimp]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Extraordinary,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Pier,
  },
  [FishType.Octopus]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Extraordinary,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Reef,
  },
  [FishType.KingCrab]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Legendary,
    lifespanClass: LifespanClass.Long,
    biome: DockBiome.Reef,
  },
  [FishType.RoyalStarfish]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Legendary,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Reef,
  },
  [FishType.SeaTurtle]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Mythical,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Reef,
  },

  // Cave ********************** 11
  [FishType.Minnow]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.VeryCommon,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Cave,
  },
  [FishType.Carp]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Common,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Cave,
  },
  [FishType.Goby]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Uncommon,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Cave,
  },
  [FishType.Tetra]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Scarce,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Cave,
  },
  [FishType.Catfish]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Rare,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Cave,
  },
  [FishType.SliderTurtle]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Epic,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Cave,
  },
  [FishType.Anglefish]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Exotic,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Cave,
  },
  [FishType.BlueCrab]: {
    weightClass: WeightClass.Medium,
    rarity: Rarity.Extraordinary,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Cave,
  },
  [FishType.Cavefish]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Legendary,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Cave,
  },
  [FishType.BottletailSquid]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Legendary,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Cave,
  },
  [FishType.Coelacanth]: {
    weightClass: WeightClass.Light,
    rarity: Rarity.Mythical,
    lifespanClass: LifespanClass.Medium,
    biome: DockBiome.Cave,
  },
};

export type RarityWeight = {
  rarity: Rarity;
  weight: number;
};

export const getSizeTagByFish = (fish: Fish): string => {
  if (fish.weight > 0.999) {
    return "Colossal ";
  }
  if (fish.weight > 0.99) {
    return "Enormous ";
  }
  if (fish.weight > 0.95) {
    return "Gigantic ";
  }
  if (fish.weight > 0.9) {
    return "Giant ";
  }
  if (fish.weight > 0.8) {
    return "Large ";
  }

  if (fish.weight < 0.001) {
    return "Atomic ";
  }
  if (fish.weight < 0.01) {
    return "Nano ";
  }
  if (fish.weight < 0.05) {
    return "Micro ";
  }
  if (fish.weight < 0.1) {
    return "Tiny ";
  }
  if (fish.weight < 0.2) {
    return "Small ";
  }

  return "";
};

export type CastStats = {
  baseCastTime: number;
  castTimeVariance: number;
  seaweedCatchRate: number;
  tangleChance: number;
  supercastChance: number;
  fishSizeMulti: number;
  fishSizeAdd: number;
  additionalFishCaughtChance: number;
  removeCommons: boolean;
  removeVeryCommons: boolean;
};

export const getCastStats = (player: Player, dock: Dock): CastStats => {
  let baseCastTime = 12;
  let castTimeVariance = 22;
  let seaweedCatchRate = 0.25;
  let tangleChance = 0.03;
  let supercastChance = 0.01;
  let fishSizeMulti = 1;
  let fishSizeAdd = 0;
  let additionalFishCaughtChance = 0;
  let removeCommons = false;
  let removeVeryCommons = false;

  switch (player.rod) {
    case ShopItemType.WoodRod:
      baseCastTime -= 4;
      if (dock.biome === DockBiome.River) {
        supercastChance += 0.15;
      }
      break;
    case ShopItemType.BambooRod:
      baseCastTime -= 6;
      fishSizeMulti = 0.7;
      break;
    case ShopItemType.GraphiteRod:
      baseCastTime -= 15;
      tangleChance += 0.03;
      castTimeVariance += 20;
      break;
    case ShopItemType.FiberglassRod:
      baseCastTime -= 8;
      castTimeVariance += 5;
      tangleChance += 0.04;
      break;
    case ShopItemType.AluminumRod:
      baseCastTime += 8;
      tangleChance += 0.06;
      fishSizeMulti = 0.4;
      fishSizeAdd += 0.6;
      if (dock.biome === DockBiome.Abyss) {
        supercastChance += 0.1;
      }
      break;
    case ShopItemType.TitaniumRod:
      baseCastTime += 34;
      tangleChance += 0.1;
      supercastChance += 0.24;
      if (dock.biome === DockBiome.Swamp) {
        seaweedCatchRate -= 0.1;
      }
      break;
    case ShopItemType.CarbonFiberRod:
      baseCastTime -= 4;
      tangleChance += 0.05;
      supercastChance += 0.08;
      break;
    case ShopItemType.Spear:
      baseCastTime += 15;
      additionalFishCaughtChance = 0.75;
      break;
    case ShopItemType.Net:
      baseCastTime += 150;
      additionalFishCaughtChance += 1 + Math.random() * 3;
      tangleChance += 0.25;
      supercastChance -= 0.08;
      break;
    case ShopItemType.Trap:
      baseCastTime += 240;
      supercastChance += 1;
      tangleChance += 0.25;
      break;
  }

  switch (player.lure) {
    case ShopItemType.Rusty:
      seaweedCatchRate -= 0.18;
      break;
    case ShopItemType.CopperHook:
      seaweedCatchRate -= 0.1;
      supercastChance += 0.04;
      break;
    case ShopItemType.Steel:
      seaweedCatchRate -= 0.02;
      supercastChance += 0.08;
      break;
    case ShopItemType.Double:
      seaweedCatchRate += 0.05;
      removeCommons = true;
      break;
    case ShopItemType.Barbed:
      seaweedCatchRate += 0.1;
      removeVeryCommons = true;
      break;
    case ShopItemType.SnapSwivel:
      seaweedCatchRate -= 0.25;
      break;
  }

  switch (player.line) {
    case ShopItemType.Braided:
      castTimeVariance += 6;
      tangleChance -= 0.01;
      break;
    case ShopItemType.Coated:
      castTimeVariance += 10;
      tangleChance -= 0.02;
      supercastChance += 0.02;
      break;
    case ShopItemType.Line3:
      castTimeVariance += 18;
      supercastChance += 0.07;
      break;
    case ShopItemType.Line4:
      castTimeVariance -= 10;
      supercastChance -= 0.04;
      break;
  }

  return {
    baseCastTime,
    castTimeVariance,
    seaweedCatchRate,
    tangleChance,
    supercastChance,
    additionalFishCaughtChance,
    fishSizeMulti,
    fishSizeAdd,
    removeCommons,
    removeVeryCommons,
  };
};
