import React from "react";
import { Box, Typography } from "@mui/material";
import CoinImage from "../../images/coin.png";
import { Dock, getCastStats, Player } from "../../config/config";
import Stat from "./Stat";
import { round2Decimal } from "../FishCard";

type StatsCardProps = {
  player: Player;
};

const StatsCard = ({ player }: StatsCardProps) => {
  const {
    baseCastTime,
    castTimeVariance,
    seaweedCatchRate,
    tangleChance,
    supercastChance,
    additionalFishCaughtChance,
    fishSizeAdd,
    fishSizeMulti,
  } = getCastStats(player, {} as Dock);

  return (
    <Box>
      {/*Tab title*/}
      <Box
        px={1}
        sx={{
          background: "#39342e",
          borderRadius: "8px 8px 0 0",
          color: "#f7ebdb",
          width: "80px",
          fontWeight: "bold",
          textAlign: "center",
          border: "2px solid",
          borderBottom: 0,
          borderColor: "#000",
        }}
      >
        <Typography variant={"body2"} fontFamily={"Monospace"}>
          Stats
        </Typography>
      </Box>

      <Box
        p={2}
        mb={2}
        sx={{
          // background: "#000",
          border: "2px solid",
          borderColor: "#000",
          color: "#fff",
          borderRadius: "0px 4px 4px 4px",
          backgroundColor: "#694e3c",
          backgroundImage:
            'url("https://www.transparenttextures.com/patterns/dark-wood.png")',
          boxShadow:
            "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.4) 0px 3px 6px",
        }}
      >
        <Stat label={"Username:"} value={player._id} />

        <Box display={"flex"} alignItems={"center"}>
          <Typography variant={"body2"} mr={1}>
            Gold:
          </Typography>
          <img src={CoinImage} alt="coin" width={"20px"} height={"20px"} />
          <Typography ml={0.5} color={"#fbd072"}>
            {player.gold.toLocaleString()}
          </Typography>
        </Box>

        <Stat
          label={"Cast time:"}
          value={`${baseCastTime}s - ${baseCastTime + castTimeVariance}s`}
        />
        <Stat
          label={"Seaweed catch rate:"}
          value={`${seaweedCatchRate * 100}%`}
        />
        <Stat
          label={"Tangle chance:"}
          value={`${round2Decimal(tangleChance * 100, 0)}%`}
        />
        <Stat label={"Supercast chance:"} value={`${supercastChance * 100}%`} />
        <Stat
          label={"Additional fish chance:"}
          value={`${additionalFishCaughtChance * 100}%`}
        />
        <Stat
          label={"Fish size modifier:"}
          value={`${fishSizeMulti}x${
            fishSizeAdd !== 0 ? ` +${fishSizeAdd}` : ""
          }`}
        />
      </Box>
    </Box>
  );
};

export default StatsCard;
