import React from "react";
import { Box, Typography } from "@mui/material";

type StatProps = {
  label: string;
  value: string;
};

const Stat = ({ label, value }: StatProps) => {
  return (
    <Box display={"flex"} alignItems={"center"}>
      {/*<Box mr={1}>{label}:</Box>*/}
      <Typography variant={"body2"} mr={1}>
        {label}
      </Typography>

      <Box
        px={1}
        sx={{
          borderRadius: "4px",
          width: "fit-content",
          textAlign: "center",
          background: "#2a2a2a",
        }}
      >
        <Typography color={"#eeeeee"} fontSize={"12px"}>
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

export default Stat;
